import { defineStore } from "pinia";
import  { computed, ref }  from "vue";
import { useHttp, useIp } from "../composables";


import jwt_decode from "jwt-decode";


export const useAuthStore = defineStore("auth", () => {
  const http = useHttp();
  const ips = useIp()

  const token = ref("");
  const iat = ref("");
  const exp = ref(null);
  const hash = ref("");
  const user = ref({});
  const id = ref(null);
  const modules = ref([]);
  const menu = ref([]);
  const permissions = ref([]);
  const groups = ref([]);
  const system = ref("");
  const sessionExpiredDialog = ref(false);
  const status = ref("");
  const showReloadDialog = ref(false)
  const isAuthenticated = computed(() => !!token.value)

  function setAuth(tokenValue) {
    token.value = tokenValue;
    iat.value = jwt_decode(tokenValue).iat;
    exp.value = jwt_decode(tokenValue).exp;
  }

  async function setUser(userId) {

    try {
      const res = await http.post(
        ips.ipUser + `user/list/${userId.id_user}`,
        {}
      );

      if (!res) return;

      user.value = res.data.rows[0];
    } catch (err) {
      console.log(err);
    }
  }

  async function loadGroups(userId) {
    try {
      const payload = {
        filter: {
          conditions: [
            {
              AndOr: "AND",
              column: "id_user",
              operator: "=",
              value: userId,
            },
          ],
        },
      };

      const res = await http.post(
        ips.ipSecurity + "user-group/list",
        payload
      );

      if (!res) return;

      groups.value = res.data.rows;
    } catch (err) {
      status.value = "error";
      throw err;
    }
  }

  async function authenticate(userData) {
    try {
      status.value = "loading";

      const payload = {
        email: userData.email,
        password: userData.password,
      };

      const res = await http.post(
        ips.ipUser + "user/login",
        payload
      );

      if (!res) return;

      hash.value = res.data.hash;
      id.value = res.data.id[0];

      permissions.value = {
        transactionCodes: res.data.transactionCodes,
      };

      Promise.all([loadGroups(id.value), setUser(id.value)]);

      setAuth(res.data.token);

      status.value = "success";

      return res;
    } catch (err) {
      status.value = "error";
      throw err;
    }
  }

  async function hashAuthenticate(hashValue) {
    hash.value = hashValue;

    try {
      const res = await http.post(ips.ipUser + "user/hash/login", {
        hash: hashValue,
      });

      if (!res) return;

      id.value = res.data.id[0];
      setUser(id.value);
      setAuth(res.data.token);

      return "success";
    } catch (err) {
      console.log(err);
      return err;
    }
  }

  function setTableObject(items) {
    menu.value = items;
  }

  function setModulesObject(items) {
    modules.value = items;
  }

  function resetState() {
    token.value = "";
    iat.value = "";
    exp.value = null;
    hash.value = "";
    user.value = {};
    id.value = null;
    modules.value = [];
    menu.value = [];
    permissions.value = [];
    groups.value = [];
    system.value = "";
    sessionExpiredDialog.value = false;
    showReloadDialog.value = false;
    status.value = "";
  }

  async function logout() {
    try {
      await http.patch(ips.ipUser + "user/update-hash", {
        hash: null,
      });
    } catch (err) {
      console.log(err);
    } finally {
      resetState();
    }
  }

  function setReloadDialog(value) {
    showReloadDialog.value = value
  }

  function setSessionExpiredDialog(value) {
    sessionExpiredDialog.value = value;
  }

  return {
    token,
    iat,
    exp,
    hash,
    user,
    id,
    modules,
    menu,
    permissions,
    groups,
    system,
    sessionExpiredDialog,
    status,
    showReloadDialog,
    isAuthenticated,
    loadGroups,
    authenticate,
    hashAuthenticate,
    setUser,
    setTableObject,
    setModulesObject,
    logout,
    setReloadDialog,
    setSessionExpiredDialog,
  };
}, {persistedState: {
  key: 'pinia',
}},);
